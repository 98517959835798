<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
        <el-table-column type="expand">
      <template slot-scope="props">
        <template>
    <el-table
      :data="tableData[0].subprojects_list"
      style="width: 100%">
      <el-table-column
        prop="service_name"
        label="站点服务名称"
        width="180">
      </el-table-column>
      <el-table-column prop="url" label="站点服务封面图">
            <template slot-scope="scope">
              <div class="image">
                <img
                  :src="scope.row.service_cover_image"
                  alt=""
                  width="120"
                  v-if="scope.row.service_cover_image"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="service_status" label="状态">
            <template slot-scope="scope">
              <el-switch
              @change="changeState(scope.row)"
                v-model="scope.row.route_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="-1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="修改路线"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除路线子项"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delPicture(scope.row.subprojects_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
    </el-table>
  </template>
      </template>
    </el-table-column>
        <el-table-column align="center" prop="route_name" label="路线名称">
          </el-table-column>
          <el-table-column prop="url" label="封面图">
            <template slot-scope="scope">
              <div class="image">
                <img
                  :src="scope.row.route_cover_image"
                  alt=""
                  width="120"
                  v-if="scope.row.route_cover_image"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="early_days" label="提前报名天数">
          </el-table-column>
          <el-table-column prop="url" label="图集">
            <template slot-scope="scope">
              <div v-if="scope.row.route_image_json">
              <div class="image" v-for="(item,i) in JSON.parse(scope.row.route_image_json)" :key="item+i">
                <img
                  :src="item"
                  alt=""
                  width="120"
                />
              </div>
          </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.route_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="-1"
              >
              </el-switch>
            </template>
          </el-table-column>

        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
       <!-- 增加场地服务标题 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
         
      >
      <div slot="title"  class="dialog-footer" >
          <div class="title" >修改路线子项</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm"  :rules="rules"> 
    <el-form-item label="排序" :label-width="formLabelWidth" >
                                   <el-input type="number" v-model="form.subprojects_sort"></el-input>
    </el-form-item> 
    <el-form-item label="状态" :label-width="formLabelWidth" >
        <el-switch
  v-model="form.route_status"
  active-color="#13ce66"
  inactive-color="#ff4949">
</el-switch>
    </el-form-item> 
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
         <el-button  type="primary" @click="updSiteSer('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import {
    PostRouteDetail,
    PostrouteSubUpdate,
    PostrouteSubDelete,
    PostServiceList
} from "@/api/routeList/routeList.js"
  
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
          var validateItem = (rule, value, callback) => {
          if (this.form.item === '') {
            callback(new Error('请输入人数'));
          } else {
            callback();
          }
        };
      //这里存放数据
      return {
           rules: {
            item: [
              { validator: validateItem, trigger: 'blur' }
            ],
            
          },
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        form:{
            subprojects_id:'',
            route_id:'',
            service_id:'',
            subprojects_sort:'',
            route_status:'',
        },
        props:{
          children:'sub',
          value:'id',
          label:'name'
        },
        //表格数据
           tableData: [],
              token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
         imgParam: {
          path: "contract/template",
        },
          fileList: [],
          //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
         options: [],
           options1: [{
            value: 0,
            label: '上门服务模板'
          }, {
            value: 1,
            label: '场地预约模板'
          }],
      };
    },
    //监听属性 类似于data概念
    computed: {
          imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
             dialogFormVisible(newVal, oldVal) {
        if (!newVal) {
          this.form = this.$options.data().form;
          this.updataFalg=false;
          this.lookFalg=false;
          this.addFlag=false;
        }
      },
    },
    //方法集合
    methods: {
        updSiteSer(){
              var data={
                subprojects_id:this.form.subprojects_id,
            route_id:this.form.route_id,
            service_id:this.form.service_id,
            subprojects_sort:this.form.subprojects_sort,
            route_status:this.form.route_status?1:0,
              }
              this.PostrouteSubUpdate(data);
        },
        changeState(item){
            this.form={
                subprojects_id:item.subprojects_id,
            route_id:item.route_id,
            service_id:item.service_id,
            subprojects_sort:item.subprojects_sort,
            route_status:item.route_status,
              }
              this.PostrouteSubUpdate(this.form);
        },
      //修改路线子项
      updataPicture(item){
          console.log(item);
          this.form={
                subprojects_id:item.subprojects_id,
            route_id:item.route_id,
            service_id:item.service_id,
            subprojects_sort:item.subprojects_sort,
            route_status:item.route_status==1?true:false,
              }
          this.dialogFormVisible=true
      },
      //删除路线子项
      delPicture(id){
        this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostrouteSubDelete({
                subprojects_id:id
            })
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
       // this.getPeopleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
      //  this.getPeopleList(data);
      },
            exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入图片事件
      imgSuccess(response, file, fileList) {
          // console.log(response)
        this.form.url = response.data.url;
      },
  
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.url = "";
      },
             //添加事件 退出弹窗
      userClose(e) {
        this.form = {
          url:'',//图片
          type:'',//空间区域0室内  1室外
        };
        (this.fileList = []), console.log("close");
      },
      /**-----------------网络请求生命周期函数--------------------- */
       //路线列表
      async PostRouteDetail(data){
          let res=await PostRouteDetail(data);
          console.log(res.data)
          this.tableData=[];
          this.tableData.push(res.data.result);
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
       //路线子项修改
      async PostrouteSubUpdate(data){
          let res=await PostrouteSubUpdate(data);
        //  console.log(res.data)
          if(res.data.status==1){
            this.dialogFormVisible=false;
            this.PostRouteDetail({
       route_id:this.$route.params.route_id
    });
            this.$message({
                message:res.data.message,
                type:'success'
            })
          }
        //   this.tableData.push(res.data.result);
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
       //路线子项删除
      async PostrouteSubDelete(data){
        console.log(data);
          let res=await PostrouteSubDelete(data);
          if(res.data.status==1){
              this.PostRouteDetail({
       route_id:this.$route.params.route_id
    });
            this.$message({
                message:res.data.message,
                type:'success'
            })
          }
        //   this.tableData.push(res.data.result);
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
    }, 
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
       this.PostRouteDetail({
       route_id:this.$route.params.route_id
    });
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
  
  <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>